import { __decorate } from "tslib";
import { Component, Prop, Vue } from "vue-property-decorator";
import Btn from '@/components/Button/Btn.vue';
let PushNotificationCard = class PushNotificationCard extends Vue {
    constructor() {
        super(...arguments);
        this.showHint = false;
        this.showDeleteModal = false;
    }
    async markRead() {
        await this.$store.dispatch('push_notifications/markAsRead', this.push_notification.id);
        await this.$store.dispatch('push_notifications/getPushNotifications');
    }
    showDeleteConfirmation() {
        this.showDeleteModal = true;
    }
    hideDeleteConfirmation() {
        this.showDeleteModal = false;
    }
    async deleteNotification() {
        this.showDeleteModal = false;
        await this.$store.dispatch('push_notifications/deleteNotification', this.push_notification.id);
        await this.$store.dispatch('push_notifications/getPushNotifications');
    }
    async viewNotificationDetails() {
        if (this.push_notification.data) {
            switch (this.push_notification.data.data.action) {
                case 'url':
                    window.open(this.push_notification.data.data.url);
                    break;
                case 'view_chat':
                    await this.$router.push(`/chat/${this.push_notification.data.data.id}`);
                    break;
                case 'view_discussion':
                    await this.$router.push(`/discussion/${this.push_notification.data.data.id}`);
                    break;
                case 'view_story':
                    await this.$router.push(`/story/${this.push_notification.data.data.id}`);
                    break;
                case 'view_lodge':
                    await this.$router.push(`/lodge/${this.push_notification.data.data.id}`);
                    break;
                case 'view_project':
                    await this.$router.push(`/project/${this.push_notification.data.data.id}`);
                    break;
                default:
                    console.log(`Invalid push notification payload.`);
            }
        }
        if (!this.push_notification.read_at) {
            this.$store.dispatch('push_notifications/markAsRead', this.push_notification.id);
            this.$store.dispatch('push_notifications/getPushNotifications');
        }
    }
};
__decorate([
    Prop()
], PushNotificationCard.prototype, "push_notification", void 0);
PushNotificationCard = __decorate([
    Component({
        components: {
            Btn
        }
    })
], PushNotificationCard);
export default PushNotificationCard;
