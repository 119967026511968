import { __decorate } from "tslib";
import { Component, Vue } from 'vue-property-decorator';
import { Getter } from 'vuex-class';
// components
import Btn from '@/components/Button/Btn.vue';
import TextInput from '@/components/FormInputs/TextInput/TextInput.vue';
import SearchInput from '@/components/FormInputs/SearchInput/SearchInput.vue';
import CoverImage from '@/components/Elements/CoverImage.vue';
import SelectInput from '@/components/FormInputs/SelectInput/SelectInput.vue';
import Pagination from '@/components/Pagination/Pagination.vue';
import PushNotificationCard from '@/views/PushNotifications/Components/PushNotificationCard.vue';
let PushNotifications = class PushNotifications extends Vue {
    constructor() {
        super(...arguments);
        this.loading = true;
        this.paginationLoaded = false;
    }
    async mounted() {
        await this.resetPushValues();
        if (this.$route.query.page) {
            await this.$store.dispatch('push_notifications/setPage', this.$route.query.page);
        }
        await this.$store.dispatch('push_notifications/getPushNotifications');
        this.paginationLoaded = true;
        this.loading = false;
    }
    async beforeDestroy() {
        await this.resetPushValues();
    }
    async resetPushValues() {
        await this.$store.dispatch('push_notifications/setPage', 1);
    }
    async paginate(page) {
        await this.$store.dispatch('push_notifications/setPage', page);
        this.$router.push({
            name: 'notifications',
            query: { page: page.toString() }
        });
    }
};
__decorate([
    Getter('getPushNotifications', { namespace: 'push_notifications' })
], PushNotifications.prototype, "push_notifications", void 0);
__decorate([
    Getter('pagination', { namespace: 'pagination' })
], PushNotifications.prototype, "pagination", void 0);
PushNotifications = __decorate([
    Component({
        components: {
            Btn,
            TextInput,
            SearchInput,
            CoverImage,
            SelectInput,
            Pagination,
            PushNotificationCard,
        },
        metaInfo() {
            return {
                title: 'Africa Geographic | notifications',
                meta: [
                    {
                        name: 'description',
                        content: 'Africa Geographic notifications',
                    },
                ],
                link: [{ rel: 'canonical', href: `${process.env.VUE_APP_URL}/notifications` }],
            };
        },
    })
], PushNotifications);
export default PushNotifications;
